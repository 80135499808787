.terms {
  font-size: 14px;
}

.terms .section {
  font-weight: bold;
}

.terms h3 {
  font-weight: 600;
  padding-top: 0.75rem;
}

.terms ol.level-1 > li {
  font-weight: bold;
  padding-top: 0.75rem;
}

.terms ol.level-1 li .section {
  display: inline;
}

.terms ol.level-2 li {
  font-weight: normal;
}

.terms ol {
  list-style: inside decimal;
  counter-reset: item;
}

.terms ol li {
  display: block;
  padding-top: 0.75rem;
}

.terms ul li {
  display: list-item;
}

.terms ol > li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.terms ol.independently-numbered > li:before {
  content: counter(item) ". ";
}

.terms p {
  padding: 0.75rem 0;
}

.terms ul li:before {
  content: "- ";
}

.terms ul li {
  padding-top: 6px;
}

.terms li p {
  padding-top: 14px;
  font-weight: normal;
}

.terms .accent-text {
  color: #00a88f;
  font-weight: bold;
}

.terms table {
  margin-bottom: 1.5rem;
}

.terms thead td {
  color: white;
  background-color: #00a88f;
  font-weight: bold;
}

.terms td {
  padding: 10px 5px;
  font-weight: normal;
}

.terms tbody {
  border: 1px solid rgb(209 213 219);
}

.terms tbody tr:nth-child(odd) {
  background-color: rgb(243 244 246);
}
